import * as React from "react";
import { graphql } from "gatsby";
import ModuleSelector from "@sections/ModuleSelector";

import { useLiveUpdates } from "@src/hooks/useLiveUpdates";
import { getContentfulPreviewPropsFn } from "@utils";
import SectionMeta from "@src/components/sections/SectionMeta";
import type { ContentfulNavigationNavMenu } from "@src/graphql/modules/navigation";

type PageProps = {
  __typename: string;
  contentful_id: string;
  title: string;
  slug: string;
};

type ItemProps = {
  childPages?: Array<PageProps>;
} & PageProps;

type LandingPageProps = {
  data: {
    navigation: ContentfulNavigationNavMenu;
    landingPage: {
      contentful_id: string;
      __typename: string;
      slug: string;
      content: Array<any>;
      seo: {
        title: string;
        description: string;
        keywords: string;
        canonical: string;
        noindex: boolean;
        nofollow: boolean;
        socialImage: {
          fields: {
            file: {
              en: {
                url: string;
              };
            };
          };
        } | null;
      };
      hero: {
        headline?: string;
        overline?: string;
        heroImage?: {
          file: {
            url: string;
          };
          resize?: {
            src: string;
          };
        };
        primaryCta?: {
          label: string;
          url: string;
        };
        secondaryCta?: {
          label: string;
          url: string;
        };
      };
    };
  };
};

const LandingPage: React.FC<LandingPageProps> = ({ data }) => {
  const { landingPage: landingPageRaw } = data;
  const landingPage = useLiveUpdates(landingPageRaw);

  const { hero = null } = landingPage;
  let content =
    landingPage.contentCollection?.items?.map((item) => ({
      ...item,
      contentful_id: item.sys.id,
      __typename: `Contentful${item.__typename}`,
    })) ?? landingPage.content;
  const getPreviewProps = getContentfulPreviewPropsFn(
    landingPage.contentful_id
  );

  // A concession to the fact that the GRT page is slightly different from the
  // rest of the pages, but close enough that we don't want to make separate components.
  // Can be done better if needed with a little more time.
  const isGrt = landingPage.slug === "globalreptrak";

  return (
    <>
      <SectionMeta data={landingPageRaw.seo} />

      <div {...getPreviewProps("hero")}>
        <ModuleSelector
          modules={[hero]}
          extras={{ isGrt }}
          useStaticArticlesGrid
        />
      </div>
      <div {...getPreviewProps("content")}>
        <ModuleSelector modules={content} useStaticArticlesGrid />
      </div>
    </>
  );
};

export default LandingPage;

export const queryLandingPage = graphql`
  query QUERY_LANDING_PAGE($id: String) {
    landingPage: contentfulLandingPage(id: { eq: $id }) {
      ...LandingPageData
    }
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
  }
`;
